import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Alert } from 'react-bootstrap';

const SoloGuidesBBS: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide '} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Jinwoos Best Blessing Stones</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_blessing.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Jinwoo's Best Blessing Stones</h1>
          <h2>
            Need help picking Best Blessing Stones for Jinwoo in Solo Leveling
            Arise? Look no further!
          </h2>
          <p>
            Last updated: <strong>18/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Where to get Blessing Stones?" />
        <p>
          Blessing Stones come mostly from the fusion, using Powder of Blessing.
          This can be obtained regularly from Path of Destruction, Workshop of
          Brilliant Light, as well as multiple events where they come mostly
          from the random blessing stones boxes. Bonds, daily deals, Premium
          Subscription, Battlepasses as well as first time clears of certain
          Story stages can also reward the player with a bunch of those.
        </p>
        <p>
          For the Stones themselves, they’ll be much more difficult to obtain
          directly, mostly from the various paid deals (Bonus, Deluxe Hunter
          Pass, paid random Boxes, etc…) or as special rewards sent out to
          everyone by Netmarble like daily login calendars, etc.
        </p>
        <p>
          In regards to the crafting option to turn a duplicate legendary
          Blessing Stone into another, random one, it is currently difficult to
          recommend doing it for F2P players as it costs 1000 essence stones and
          can result in obtaining a useless Stone. If you’re on the heavier side
          of the spending, do it at your own discretion, considering that there
          could eventually be a higher tier of runes and Blessing Stones that
          could require legendary rarities as fusion fodders.
        </p>
        <SectionHeader title="Different Types of Stones" />
        <p>
          Blessings Stones come in multiple shapes and forms, some will be
          universal damage buffs, while others will be more situational. There
          will even be Stones focused on utility and defense, to give more
          agency to the players when building their teams.
        </p>
        <Alert variant="primary">
          <p>
            At the time of writing this guide, a re-balance of some of the
            Blessing Stones has been announced by Netmarble, so take the
            analyses of the Stones with a grain of Salt, especially the weaker
            ones.
          </p>
        </Alert>
        <h5>Universal Damage Stones</h5>
        <StaticImage
          src="../../../images/solo/generic/blessing_1.webp"
          alt="Guide"
        />
        <p>
          Here are the Stones that will, almost always, give you a boost in
          damage. Some will be weaker, while some will be extremely powerful,
          but the sheer range of applications for these stones make them viable
          or even core picks for almost every content in the game.
        </p>
        <ul>
          <li>
            <strong>Reawakening</strong> - This stone, like most in this
            section, is simple: it gives you cooldown reduction (CDR) which not
            only will give you more damage as your skills will become more
            spammable, but will also increase the Break AND Buff potential of
            your Jinwoo depending on the skills you play. This Stone even works
            on the weapon skills, which represent a big part of almost all
            endgame builds for Jinwoo currently. The only time this stone loses
            value is in extremely short fight scenarios in very end game like
            the Battlefield of Time, where certain boss fights won’t last long
            enough to fully use this Stone.
          </li>
          <li>
            <strong>Sharp Perception</strong> - This will give you a stackable
            Crit Rate buff upon hitting an enemy, making it a strong option
            whenever you struggle to maximize your Crit Rate, such as using
            Supports with no Crit buffs, or under high power deficit. Removing
            this Stone would mean one of three things: either you can maximize
            your Crit Rate without it, you’re not relying on Crit, or perhaps
            you’re not afraid of retrying a stage until rng smiles upon you.
            Keep in mind, the value is not very high at base rarity, so consider
            this only at purple or above.
          </li>
          <li>
            <strong>Double-edged Sword </strong> - This is another stacking type
            Stone, this time for the Attack stat. Hitting the enemy will make
            your Jinwwo stronger, but will also make the enemy stronger by
            increasing its attack by the same percentage. Realistically, this
            Stone will be removed any time the damage of the enemies is a threat
            to you or your team, such as in Gem stages when pushing at high
            deficit, as your Hunters will likely not dodge the opponents’
            attacks and will take the full brunt of up to 3 bosses with
            increased attack. Scary stuff.
          </li>
          <li>
            <strong>Bloodlust</strong> - Once again, a very versatile Stone.
            Whenever abusing Shadow Step proves difficult, Bloodlust offers a
            consistent way of decreasing the opponent’s defense upon each
            successful hit from Jinwoo, while also decreasing the enemy’s Attack
            stat whenever they manage to hit your Jinwoo. A highly sought after
            combo of both offensive and defensive utility will make this Stone a
            safe pick in almost every situation.
          </li>
          <li>
            <strong>Mana Rampage</strong> - This is a powerful, but tricky Stone
            to play with. Its attack boost, while not as potent as Double-edged
            Sword, is active at all times and does not require any ramp up. The
            downside is that every skill will cost more mana, making resource
            management a challenge especially at higher weapon advancements and
            with Reawakening.
          </li>
          <li>
            <strong>Advanced Dagger Technique</strong> - Honorable mention being
            the only direct damage buff in this list, as its activation
            condition can be met early on in almost every fight, making it a
            very safe pick even though the value of the buff itself will be
            weaker than the more situational damage buffs.
          </li>
        </ul>
        <h5>Situational Damage Stones</h5>
        <StaticImage
          src="../../../images/solo/generic/blessing_2.webp"
          alt="Guide"
        />
        <p>
          These Stones have very high disparities in their usefulness, with some
          of them borderline useless as of the time of writing this guide, so we
          will not stay too long on those.
        </p>
        <ul>
          <li>
            <strong>Monarch’s Domain</strong> is currently an underwhelming
            skill, its damage being conditional to using the Shadows and having
            them stay alive, which is a challenge against powerful bosses. Even
            then, the damage buff itself is weak and barely noticeable as it
            stands.
          </li>
          <li>
            <strong>Pulverize </strong>is not simply an excellent way of
            improving Jinwoo’s break, but is also the premier pick for extreme
            burst builds centered around one shotting bosses with a Break bar.
            It will also prove to be very efficient when fighting bosses with
            recurring Break bars. Using this Stone in the Battlefield of Time or
            in nuke builds (such as Ult centered builds) will make you question
            your damage once you go back to the rest of the content.
          </li>
          <ul>
            <li>
              Important to noe that in Path of Destruction, destroying both
              hands of Ennio and revealing the head counts as breaking, making
              this Stone best in slot for Ennio as long as you are able to time
              your burst properly.
            </li>
          </ul>
          <li>
            <strong>Title: Wolf Assassin</strong>is only ever useful in stages
            where there is no boss, don’t pick it up otherwise.
          </li>
          <li>
            <strong>Title: Conqueror of Adversity</strong>is a terrible Stone
            for now, its buff being ridiculously weak both offensively and
            defensively.
          </li>
          <li>
            <strong>Chains of Blood</strong> can theoretically offer a high
            damage boost when playing at very low Health, but getting there,
            then staying at such a low amount of HP without dying or healing
            yourself too much is extremely risky, and won’t even work on most
            speed challenges as it would take way too long to get your HP low
            enough. With high mastery over a fight however, this Stone could
            easily become amazing for challenging encounters if you let yourself
            get hit once or twice at the beginning of the fight. An example
            would be to let Vulcan hit you early on then benefit from a massive
            damage boost for the rest of the fight. Avoid at low rarity.
          </li>
          <li>
            <strong>Boss Slayer</strong> is a fairly simple, yet potent Stone.
            It offers a high damage boost against Bosses, at the cost of slower
            dash regeneration. This downside might not seem too bad, but if you
            rely on A3 Plum Sword it will require precise gameplay to fully
            benefit from both buffs.
          </li>
          <li>
            <strong>Assassin’s Proficiency</strong> increases damage when
            hitting enemies in the back, which is not that good with all the
            movements involved in the gameplay. What’s good however, is that any
            damage dealt to Ennio is counted as back damage, making this Stone
            very valuable in Path of Destruction.
          </li>
          <li>
            <strong>Weakness Detection</strong> greatly increases damage when
            hitting with the elemental advantage, making this Stone especially
            good when the player has access to a wide variety of weapons. If
            you’re the type to play dark weapons in almost every content then
            you can safely ignore this Stone for the most part, but as you
            progress and advance diverse weapons and skill runes this Stone will
            eventually get better, to the point where it will become a
            cornerstone of your elemental builds.
          </li>
        </ul>
        <h5>Defensive/Pure Utility Stones</h5>
        <StaticImage
          src="../../../images/solo/generic/blessing_3.webp"
          alt="Guide"
        />
        <p>
          To quickly go over these Blessing Stones, the main takeaway is that
          they are practically useless when it comes to increasing your damage.
          They offer no offensive buffs whatsoever, and they will mostly be
          crutches for players struggling to clear challenging content and would
          rather take a little more time in exchange for having improved
          survivability.
        </p>
        <p>
          Keep in mind the earlier disclaimer, as most of these Stones are
          planned to be rebalanced we will properly cover them when they finally
          become viable.
        </p>
        <p>
          That said, speaking about July 31st 2024 Rebalance, Overall not much
          has changed, utility stones are still suboptimal options but they’re
          now a bit better at what they already did. Honorable mentions to
          Title: Demon Slayer and Kasaka’s Steel Scales as we expect autoplay to
          become more frequent eventually and those two could see use there.
        </p>
        <p>
          As of now, you should not play any of those Blessing Stones with the
          intent of increasing your damage/clear times.
        </p>
        <p>
          An honorable mention to Daily Quest, since it gives you a second
          chance when learning patterns and gives you a chance to play more
          aggressively and Speed. Increasing your speed and reducing dash
          cooldown can be invaluable in fights that require a lot of mobility.
        </p>
        <p>
          With the rebalance of the July 31st update now out, we expect the
          Title: Demon slayer and the Kasaka’s Steel Scales to become more
          relevant in AUTO PLAY. Indeed, on manual there is little to no reason
          to seek more Mana or more Shield, but it could benefit Auto setups
          where your Jinwoo will tend to spam skills but not basic attacks and
          tank most of the enemies’ attacks. The loss of damage would not be too
          bothersome as you should only be using Auto for easy fights.
        </p>
        <SectionHeader title="General Advice & Recommendations" />
        <p>
          In general, CDR and Damage buffing Stones will be the ones you’ll use
          the most, with the Crit, Attack and Defense shred coming behind.
        </p>
        <p>
          It will be crucial however to ensure that you can fulfill the
          conditions of the situational Stones, as these will only excel in
          their particular niches, unlike the universal Stones that will yield a
          lower payoff but be more consistent and versatile across any and all
          content.
        </p>
        <p>
          If you do not need them to survive, avoid any non offensive Blessing
          Stones as these will be strict DPS losses compared to the rest.
        </p>
        <p>
          Lastly, between two Stones of similar effect, pick the one of the
          higher rarity, and do not hesitate to use the more generic Stones
          instead of the situational ones if there is a huge disparity between
          their rarities.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesBBS;

export const Head: React.FC = () => (
  <Seo
    title="Jinwoo's Best Blessing Stones | Solo Leveling: Arise | Prydwen Institute"
    description="Need help picking Best Blessing Stones for Jinwoo in Solo Leveling
            Arise? Look no further!"
    game="solo"
  />
);
